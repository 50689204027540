import { PersonalDataModel } from "../../core/models/personal-data-model";
import LinkItem from "../components/shared/elements/LinkItem";
import { toAbsoluteUrl } from "../../content/helpers";

type Props = {
  personalData: PersonalDataModel;
  date: any;
};

const UserMenu: React.FC<Props> = ({ personalData, date }) => {
  return (
    <>
      <div className="flex w-full items-center justify-between space-x-6 pt-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-md font-bold text-white">
              <>Hi {personalData?.name},</>
            </h3>
          </div>
        </div>
        <LinkItem url="/ADI/settings/overview">
          <img
            src={toAbsoluteUrl("/assets/media/svg/settings.svg")}
            className="h-[26px] w-[26px] flex-shrink-0"
            alt="doc icon"
          />
        </LinkItem>
      </div>
    </>
  );
};

export { UserMenu };
